var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Comunidades / Cidades Autónomas')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.comunidades,"append-to-body":"","calculate-position":_vm.withPopper,"multiple":true,"loading":_vm.loader.comunidade,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getProvinciasByComunidade,"change":_vm.getProvinciasByComunidade},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,4124409017),model:{value:(_vm.localization.comunidade),callback:function ($$v) {_vm.$set(_vm.localization, "comunidade", $$v)},expression:"localization.comunidade"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma comunidade / cidade autónoma'))+" ")])]):_vm._l((_vm.viewData.comunidades),function(txt,index){return _c('p',{key:("comunidade-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Províncias')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.provinciasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.provincia,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getComarcaByProvincia,"change":_vm.getComarcaByProvincia},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Províncias de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,1973686804),model:{value:(_vm.localization.provincia),callback:function ($$v) {_vm.$set(_vm.localization, "provincia", $$v)},expression:"localization.provincia"}},[(_vm.localization.comunidade)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma província'))+" ")]):_vm._e(),(!_vm.localization.comunidade)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha pelo menos uma comunidade / cidade autónoma'))+" ")]):_vm._e()]):_vm._l((_vm.viewData.provincias),function(txt,index){return _c('p',{key:("provincia-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Comarcas')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.comarcasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.comarca,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getMunicipiosByComarca,"change":_vm.getMunicipiosByComarca},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Comarcas de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,3633880887),model:{value:(_vm.localization.comarca),callback:function ($$v) {_vm.$set(_vm.localization, "comarca", $$v)},expression:"localization.comarca"}},[(_vm.localization.provincia)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma comarca'))+" ")]):_vm._e(),(!_vm.localization.provincia)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha pelo menos uma província'))+" ")]):_vm._e()]):_vm._l((_vm.viewData.comarcas),function(txt,index){return _c('p',{key:("comarca-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Municípios')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.municipiosGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.municipio,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getZonasByMunicipio,"change":_vm.getZonasByMunicipio},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Municípios de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,150566944),model:{value:(_vm.localization.municipio),callback:function ($$v) {_vm.$set(_vm.localization, "municipio", $$v)},expression:"localization.municipio"}},[(_vm.localization.comarca)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum município'))+" ")]):_vm._e(),(!_vm.localization.comarca)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha pelo menos uma comarca'))+" ")]):_vm._e()]):_vm._l((_vm.viewData.municipios),function(txt,index){return _c('p',{key:("municipio-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Zonas')}},[(_vm.view===false)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.zonasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.zona,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Zonas de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}],null,false,3098244669),model:{value:(_vm.localization.zona),callback:function ($$v) {_vm.$set(_vm.localization, "zona", $$v)},expression:"localization.zona"}},[(_vm.localization.municipio)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma zona'))+" ")]):_vm._e(),(!_vm.localization.municipio)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Escolha pelo menos um município'))+" ")]):_vm._e()]):_vm._l((_vm.viewData.zonas),function(txt,index){return _c('p',{key:("zona-" + index),staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(txt)+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }