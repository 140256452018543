import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    comunidades: state => state.comunidades.value,
    provincias: state => state.provincias.value,
    comarcas: state => state.comarcas.value,
    municipios: state => state.municipios.value,
    zonas: state => state.zonas.value,
    floors: state => state.floors.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setComunidades(state, payload) {
      state.comunidades.value = []
      if (Array.isArray(payload)) {
        state.comunidades.value = payload
      }
    },
    setProvincias(state, payload) {
      state.provincias.value = []
      if (Array.isArray(payload)) {
        state.provincias.value = payload
      }
    },
    setComarcas(state, payload) {
      state.comarcas.value = []
      if (Array.isArray(payload)) {
        state.comarcas.value = payload
      }
    },
    setMunicipios(state, payload) {
      state.municipios.value = []
      if (Array.isArray(payload)) {
        state.municipios.value = payload
      }
    },
    setZonas(state, payload) {
      state.zonas.value = []
      if (Array.isArray(payload)) {
        state.zonas.value = payload
      }
    },
    setFloors(state, payload) {
      state.floors.value = []
      if (Array.isArray(payload)) {
        state.floors.value = payload
      }
    },
  },
  actions: {
    async getAllComunidades({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}country/getNivel1`)
          .then(response => {
            try {
              if (typeof response.data.level === 'object') {
                commit('setComunidades', response.data.level)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar as comunidades / cidades autónomas')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as comunidades / cidades autónomas')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as comunidades / cidades autónomas')))
            }
          })
      })
    },

    async getAllProvinciasByComunidade({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.level1)) {
          formData.append('group', true)
          payload.level1.forEach(row => {
            formData.append('sw119s06[]', row.id)
          })
        } else {
          formData.append('sw119s06', payload.level1 || '')
        }

        api.post(`${apiConfig.url_base_api}country/getNivel2`, formData)
          .then(response => {
            try {
              if (typeof response.data.level === 'object') {
                commit('setProvincias', response.data.level)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar as províncias')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as províncias')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as províncias')))
            }
          })
      })
    },

    async getAllComarcasByProvincia({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.level1)) {
          payload.level1.forEach(row => {
            formData.append('sw119s06[]', row.id)
          })
        } else {
          formData.append('sw119s06', payload.level1 || '')
        }

        if (Array.isArray(payload.level2)) {
          const sw119s07 = []
          payload.level2.forEach(row => {
            sw119s07.push(row.id)
          })
          formData.append('sw119s07', sw119s07.join(';') || '')
        } else {
          formData.append('sw119s07', payload.level2 || '')
        }

        if (Array.isArray(payload.level1) && Array.isArray(payload.level2)) {
          formData.append('group', true)
        }

        api.post(`${apiConfig.url_base_api}country/getNivel3`, formData)
          .then(response => {
            try {
              if (typeof response.data.level === 'object') {
                commit('setComarcas', response.data.level)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar as comarcas')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as comarcas')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as comarcas')))
            }
          })
      })
    },

    async getAllMunicipiosByComarca({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.level1)) {
          payload.level1.forEach(row => {
            formData.append('sw119s06[]', row.id)
          })
        } else {
          formData.append('sw119s06', payload.level1 || '')
        }

        if (Array.isArray(payload.level2)) {
          const sw119s07 = []
          payload.level2.forEach(row => {
            sw119s07.push(row.id)
          })
          formData.append('sw119s07', sw119s07.join(';') || '')
        } else {
          formData.append('sw119s07', payload.level2 || '')
        }

        if (Array.isArray(payload.level3)) {
          const sw119s08 = []
          payload.level3.forEach(row => {
            sw119s08.push(row.id)
          })
          formData.append('sw119s08', sw119s08.join(';') || '')
        } else {
          formData.append('sw119s08', payload.level3 || '')
        }

        if (Array.isArray(payload.level1) && Array.isArray(payload.level2) && Array.isArray(payload.level3)) {
          formData.append('group', true)
        }

        api.post(`${apiConfig.url_base_api}country/getNivel4`, formData)
          .then(response => {
            try {
              if (typeof response.data.level === 'object') {
                commit('setMunicipios', response.data.level)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os municípios')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os municípios')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os municípios')))
            }
          })
      })
    },

    async getAllZonasByMunicipio({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (Array.isArray(payload.level1)) {
          payload.level1.forEach(row => {
            formData.append('sw119s06[]', row.id)
          })
        } else {
          formData.append('sw119s06', payload.level1 || '')
        }

        if (Array.isArray(payload.level2)) {
          const sw119s07 = []
          payload.level2.forEach(row => {
            sw119s07.push(row.id)
          })
          formData.append('sw119s07', sw119s07.join(';') || '')
        } else {
          formData.append('sw119s07', payload.level2 || '')
        }

        if (Array.isArray(payload.level3)) {
          const sw119s08 = []
          payload.level3.forEach(row => {
            sw119s08.push(row.id)
          })
          formData.append('sw119s08', sw119s08.join(';') || '')
        } else {
          formData.append('sw119s08', payload.level3 || '')
        }

        if (Array.isArray(payload.level4)) {
          const sw119s09 = []
          payload.level4.forEach(row => {
            sw119s09.push(row.id)
          })
          formData.append('sw119s09', sw119s09.join(';') || '')
        } else {
          formData.append('sw119s09', payload.level4 || '')
        }

        if (Array.isArray(payload.level1) && Array.isArray(payload.level2) && Array.isArray(payload.level3) && Array.isArray(payload.level4)) {
          formData.append('group', true)
        }

        api.post(`${apiConfig.url_base_api}country/getNivel5`, formData)
          .then(response => {
            try {
              if (typeof response.data.level === 'object') {
                commit('setZonas', response.data.level)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar as zonas')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as zonas')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as zonas')))
            }
          })
      })
    },

    async fillLocalizationByCodePostal({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw119s11', payload.codigoPostal || '')

        api.post(`${apiConfig.url_base_api}country/fillCountryAdminLevelByCodePostal`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.level1_sel && response.data.level2_sel && response.data.level3_sel && response.data.level4_sel && response.data.level5_sel) {
                  if (typeof response.data.level1 === 'object') {
                    commit('setComunidades', response.data.level1)
                  }

                  if (typeof response.data.level2 === 'object') {
                    commit('setProvincias', response.data.level2)
                  }

                  if (typeof response.data.level3 === 'object') {
                    commit('setComarcas', response.data.level3)
                  }

                  if (typeof response.data.level4 === 'object') {
                    commit('setMunicipios', response.data.level4)
                  }

                  if (typeof response.data.level5 === 'object') {
                    commit('setZonas', response.data.level5)
                  }

                  resolve({
                    comunidade: response.data.level1_sel,
                    provincia: response.data.level2_sel,
                    comarca: response.data.level3_sel,
                    municipio: response.data.level4_sel,
                    zona: response.data.level5_sel,
                    coordenadas: ((response.data.cood_lat && response.data.cood_long) ? `${response.data.cood_lat},${response.data.cood_long}` : null),
                  })
                } else {
                  reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a localização do código postal')))
            }
          })
      })
    },

    async getAllFloors({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}localization/getAllFloors`)
          .then(response => {
            try {
              if (typeof response.data.floors === 'object') {
                commit('setFloors', response.data.floors)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os andares')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os andares')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os andares')))
            }
          })
      })
    },

  },
}
