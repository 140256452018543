import { ref } from '@vue/composition-api'

const defaultState = () => ({
  comunidades: ref([]),
  provincias: ref([]),
  comarcas: ref([]),
  municipios: ref([]),
  zonas: ref([]),
  floors: ref([]),
})

export default defaultState
